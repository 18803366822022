<template >
  <div class="Finished">
    <HeaderMenu />
    <RouteDir />
    <div v-if="charge_pending ==true" class="finished-cont">
        <div v-if="!payment.status ">
          <div v-if="error ==false" class="header">
             <p  class="m1">CARGANDO...</p>
          </div>
         <div v-else class="header">
             <p  class="m1">ERROR</p>
              <p class="m2">NO SE HA ENCONTRADO LA TRANSACCIÓN</p>
          </div>
       
           
      </div>
      <div v-else-if="payment.status == 'CARGO PENDIENTE'" class="header">
        <p class="m1">CARGO PENDIENTE</p>
        <p class="m2">Tu cargo esta en proceso de validación.</p>
      </div>
      <div v-else class="header">
       
          <p class="m1">NO SE HA PODIDO COMPLETAR LA TRANSACCIÓN</p>
        <p v-if="payment.status == 'FALLIDO'" class="m2">CARGO FALLIDO</p>
         <p v-if="payment.status == 'CANCELADO'"  class="m2">CARGO CANCELADO</p>
          <p v-if="payment.status == '3D SECURE EXPIRADO'"  class="m2">EL PROCESO DE VALIDACIÓN HA EXPIRADO</p>
      </div>
    </div>
    <div v-else class="finished-cont">
    
      <div class="header">
        <p class="m1">¡GRACIAS!</p>
        <p class="m2">TU PEDIDO HA SIDO RECIBIDO</p>
      </div>
      <div class="rowCont f1">
        <div class="colCont">
          <p class="titulo">NO. DE PEDIDO</p>
          <p>{{payment.pay_num}}</p>
        </div>
        <div class="colCont">
          <p class="titulo">FECHA</p>
          <p>{{payment.created_at}}</p> <!-- formatear a  octubre 21, 2021 -->
        </div>
        <div class="colCont">
          <p class="titulo">TOTAL</p>
          <p>${{payment.total}}</p>
        </div>
        <div class="colCont">
          <p class="titulo">MÉTODO DE PAGO</p>
          <p>{{payment.method}}</p>
        </div>
      </div>
      <div class="rowCont f1">
      
        <div class="colCont">
          <p class="titulo">EMAIL</p>
          <p>{{payment.email}}</p> 
        </div>
        <div class="colCont">
          <p class="titulo">CEL</p>
          <p>{{payment.phone}}</p>
        </div>
        <div class="colCont">
          <p class="titulo">MÉTODO DE ENVÍO</p>
          <p>{{payment.shipping_option}}</p>
        </div>
        <div class="colCont" v-if="payment.method == 'Openpay'">
          <p class="titulo">Openpay Orden Id</p>
          <p>{{payment.order_id}}</p>
        </div>
      </div>
      <div class="rowCont f1" v-if="payment.shipping_option == 'Envío a domicilio'">
        <div class="colCont">
          <p class="titulo">ESTADO</p>
          <p>{{payment.state}}</p>
        </div>
        <div class="colCont">
          <p class="titulo">CIUDAD</p>
          <p>{{payment.city}}</p>
        </div>
        <div class="colCont">
          <p class="titulo">DIRECCIÓN</p>
          <p>{{payment.address}}</p>
        </div>
        <div class="colCont">
          <p class="titulo">CÓDIGO POSTAL</p>
          <p>{{payment.postal_code}}</p>
        </div>
      </div>
      <div class="rowCont f1" v-if="payment.shipping_option == 'Ordena y Recoge'">
        <div class="colCont">
          <p class="titulo">RECOGER EN SUCURSAL</p>
          <p>{{payment.carrier}}</p>
        </div>
       
      </div>
      <div class="rowCont">
        <div class="tabla-pedido">
          <div class="header-tab">DETALLES DEL PEDIDO</div>
          <div class="t-p-cont">
            <div class="rowCont">
              <div class="colCont">
                <div class="row-t sbtwn tw1">
                  <p class="titulo-1">PRODUCTO</p>
                  <p class="titulo-1">PRECIO</p>
                </div>
                <div v-for="(item, key) in payment.detail" :key="key" class="row-t sbtwn">
                  <div class="product-c">
                    <p><b>{{item.name}}</b></p>
                   <!-- <p>1 KG</p> -->
                    <p class="cant-p">x{{item.quantity}}</p>
                    <p class="cant-extra">{{item.extra}}</p>
                  </div>
                  <p>${{item.subtotal}}</p>
                </div>
              </div>
              <div class="colCont mdl dp1">
                <div class="row-t sbtwn">
                  <p class="titulo-2"><b>SUBTOTAL</b></p>
                  <p class="second-c">${{payment.subtotal}}</p>
                </div>
                <div class="row-t sbtwn">
                  <p class="titulo-2 second-c"><b>ENVÍO</b></p>
                  <p class="second-c">{{payment.envio}}</p>
                </div>
              </div>
              <div class="colCont dp1">
                <div class="row-t sbtwn">
                  <p class="titulo-2 we-c"><b>MÉTODO DE PAGO</b></p>
                  <p class="second-c">{{payment.method}}</p>
                </div>
                <div class="row-t sbtwn">
                  <p class="titulo-1 we-c totale">TOTAL</p>
                  <p class="titulo-1 orange second-c totale">${{payment.total}}</p>
                </div>
              </div>
            </div>
            
            <div class="rowCont listRowCont" v-if="validBilling(payment)">
              <div class="row-t">
                <p class="titulo-1 orange">RAZÓN SOCIAL</p>
                <p>{{payment.billing.reason_social}}</p>
              </div>
              <div class="row-t">
                <p class="titulo-1 orange">RFC</p>
                <p>{{payment.billing.rfc}}</p>
              </div>
              <div class="row-t">
                <p class="titulo-1 orange">DIRECCIÓN FISCAL</p>
                <p>{{payment.billing.fiscal_address}}</p>
              </div>
              <div class="row-t">
                <p class="titulo-1 orange">CÓDIGO POSTAL</p>
                <p>{{payment.billing.postal_code}}</p>
              </div>
              <div class="row-t">
                <p class="titulo-1 orange">CORREO ELECTRÓNICO</p>
                <p>{{payment.billing.email}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <Footer />
  </div>
</template>
<script>
import HeaderMenu from "../../components/fixedItems/HeaderMenu.vue";
import Footer from "../../components/fixedItems/Footer.vue";
import RouteDir from "../../components/fixedItems/RouteDir.vue";
export default {
  components: {
    HeaderMenu,
    Footer,
    RouteDir,
  },
  data(){
    return{
      payment:'',
       charge_pending:true,
       error:false,
    }
  },
  
  metaInfo() {
    return {
      title: 'Compra finalizada',
      titleTemplate: "%s | Agriga de México",
      htmlAttrs: {
        lang: "es",
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:'Compra finalizada.',
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
      ],
    };
  },
  async created(){
     const {id} = this.$route.query
        if(!id){
             this.$router.push("/").catch(err => {});
        }

        //enviar a validacion.
       const response = await  this.$store.dispatch("main/editItem",  {option:'charge_status', item:{ "order_id":id}});
       
       this.payment =  response.payment
       


       if(response.status == 'error'){
          this.charge_pending = true//this.$router.push("/error").catch(err => {});
          
        this.error =true
       }

      this.payment =  response.payment
    

       if(response.result.status == 'charge_pending'){
         this.charge_pending = true
       }else if(response.result.status != 'completed'){
         this.charge_pending = true// this.$router.push("/error").catch(err => {});
       }else{
         this.charge_pending = false
       }
  },
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                let name= this.titulo;
                document.title = to.meta.title || `Finalizado - Agriga de México`;
            }
        },
  },
  methods:{
    validBilling(payment){
      if(!payment){
        return false
      }
      if(payment.billing =='error' ||  payment.billing =='No se encontraron coincidencias.'){
        return false
      }

      if(payment?.billing?.reason_social ){
        return false
      }
       if(payment?.billing?.rfc ){
        return false
      }
       if(payment?.billing?.fiscal_address ){
        return false
      }
       if(payment?.billing?.postal_code ){
        return false
      }
       if(payment?.billing?.email ){
        return false
      }
       

       return true
        



    }
  }
};
</script>
<style scoped>
p {
  margin: 0;
}
.finished-cont {
  width: 67.5vw;
  margin: 6.979vw auto 17.396vw;
}
.tabla-pedido {
  width: 100%;
  border: 1px solid gainsboro;
}
.header p {
  text-align: center;
}
.header p.m1 {
  font-family: "Intro Rust";
  font-weight: 400;
  font-size: 3.75vw;
  line-height: 5.99vw;
  color: #3ebdb1;
}
.header p.m2 {
  font-family: "Intro Rust";
  font-weight: 400;
  font-size: 1.875vw;
  line-height: 2.865vw;
  color: #fe9128;
}
.rowCont {
  display: flex;
  justify-content: space-between;
}
.rowCont p {
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.771vw;
  color: #8293a2;
}
.colCont p.titulo {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.51vw;
  text-align: center;
  text-decoration-line: underline;
  color: #3ebdb1;
}
.header-tab {
  height: 5.677vw;
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.563vw;
  line-height: 1.875vw;
  text-align: center;
  color: #ffffff;
  background: #3ebdb1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.row-t {
  display: flex;
}
.row-t.sbtwn {
  justify-content: space-between;
}
.colCont {
  width: 100%;
}
p.titulo-1 {
  font-family: "Intro Rust";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 1.51vw;
  color: #3ebdb1;
}
.listRowCont {
  flex-direction: column;
  margin-top: 4.323vw;
  padding-top: 4.323vw;
  border-top: 1px solid gainsboro;
}
.listRowCont .titulo-1 {
  width: 14.844vw;
}
.f1 {
  text-align: center;
  margin: 3.385vw 0;
}
.mdl {
  padding: 0 2.604vw;
  margin: 0 2.604vw;
  border-left: 1px solid gainsboro;
  border-right: 1px solid gainsboro;
}
.tabla-pedido .rowCont p {
  font-size: 0.781vw;
  line-height: 1.094vw;
}
.tabla-pedido .rowCont p.cant-extra {
  font-size: 0.581vw;
  line-height: 1.094vw;
}
.t-p-cont {
  padding: 2.917vw 5.313vw;
}
.orange {
  color: #fe9128 !important;
}
.we-c {
  width: 4vw;
}
.second-c {
  width: 7.448vw;
}
.tw1 {
  margin-bottom: 0.729vw;
}
.dp1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 7.344vw;
}
.listRowCont .row-t {
  margin-bottom: 1.458vw;
}
.cant-p {
  margin-top: 1.042vw !important;
}
.totale {
  font-size: 1.25vw !important;
}
@media (max-width: 768px) {
  .tw1 {
    margin-bottom: 0.729vw;
    font-size: 4.673vw;
  }
  .finished-cont {
    width: 82.009vw;
    margin: 14.252vw auto 20.093vw;
  }
  .header p.m1 {
    font-size: 11.682vw;
    line-height: 12.99vw;
  }
  .header p.m2 {
    font-size: 7.009vw;
    line-height: 8vw;
    width: 65vw;
    margin: auto;
  }
  .rowCont {
    flex-direction: column;
  }
  .f1 {
    flex-direction: column;
    margin: 14.252vw 0 7.944vw;
  }
  .f1 .colCont {
    margin-bottom: 11.215vw;
  }
  .colCont p.titulo {
    font-size: 5.607vw;
    line-height: 5.607vw;
    margin-bottom: 4.206vw;
  }
  .rowCont p {
    font-size: 5.607vw;
    line-height: 7.944vw;
  }
  .header-tab {
    font-size: 7.009vw;
    line-height: 8.411vw;
    padding: 4.439vw 17vw;
    height: auto;
  }
  .mdl {
    padding: 7.477vw 0 0;
    margin: 7.477vw 0 0;
    border-top: 1px solid gainsboro;
    border-left: none;
    border-right: none;
  }
  .t-p-cont {
    padding: 5.841vw;
  }
  .tabla-pedido .rowCont p {
    font-size: 3.505vw;
    line-height: 4.907vw;
  }
  .dp1 {
    height: auto;
  }
  .second-c {
    width: 33vw;
  }
  .we-c {
    width: 24vw;
  }
  .totale {
    font-size: 5.607vw !important;
  }
  .listRowCont .titulo-1 {
    width: auto;
  }
  .listRowCont .row-t {
    margin-bottom: 2.804vw;
    flex-direction: column;
  }
  .listRowCont {
    margin-top: 8.645vw;
    padding-top: 6.542vw;
  }
  .row-t.sbtwn {
    margin-bottom: 3.738vw;
  }
}
</style>